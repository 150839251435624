import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchEditorsChoiceArticles } from '../../store/editorsChoice'
import IonArticle from 'ion-article'

class EditorsChoice extends Component {
  constructor (props) {
    super(props)
    const articles = props.articles[props.section]
    if (!articles || !articles.length) {
      props.fetchEditorsChoiceArticles(props.section)
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.section !== this.props.section) {
      this.props.fetchEditorsChoiceArticles(this.props.section)
    }
  }

  render () {
    const rawArticles = this.props.articles
    if (rawArticles && rawArticles.length) {
      const articles = rawArticles.map(article => new IonArticle(article))
      return (
        <div className='editors-choice'>
          <h3><span>Umbono woMhleli</span></h3>
          {articles.slice(0, this.props.pageSize).map((article, index) => {
            return (
              <article key={article.contentKey}>
                <Link to={'/' + article.getCanonicalUri()}>
                  <span>{index + 1}</span>
                  <h4>{article.headline}</h4>
                </Link>
              </article>
            )
          })}
        </div>
      )
    } else if (this.props.isFetching) {
      return (
        <div>
          <p>Fetching most read articles...</p>
        </div>
      )
    } else {
      return ''
    }
  }
}

EditorsChoice.propTypes = {
  fetchEditorsChoiceArticles: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  title: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
}

EditorsChoice.defaultProps = {
  pageSize: 5
}

const mapStateToProps = (state) => (state.editorsChoice)
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchEditorsChoiceArticles }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EditorsChoice)
