import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchMostReadArticles } from '../../store/mostReadArticles'

import { Articles } from 'ion-article-cmp'
import NoImage from '../static/no-image.png'

class MostReadArticles extends Component {
  render () {
    return (
      <div className='most-read'>
        <h3><span>Eziphambili</span></h3>
        <Articles
          className='article-list paged' section='news' page0Limit={5} pageSize={5} fetchArticles={this.props.fetchMostReadArticles}
          noImage={NoImage} notFound={() => (<div>Oops!</div>)} fetchMoreArticles={() => { }} articles={this.props.articles}
          component={({ articles, authorName, noImage, moreButton }) => (
            <>
              {articles.map((article, index) => (
                <article key={article.contentKey}>
                  <Link to={'/' + article.getCanonicalUri()}>
                    <span>{index + 1}</span>
                    <h4>{article.headline}</h4>
                  </Link>
                </article>
              ))}
            </>)}
        />
      </div>
    )
  }
}

MostReadArticles.propTypes = {
  articles: PropTypes.object.isRequired,
  fetchMostReadArticles: PropTypes.func.isRequired
}

MostReadArticles.defaultProps = {
  pageSize: 5
}

const mapStateToProps = (state) => ({ articles: state.mostReadArticles })
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchMostReadArticles }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MostReadArticles)
