import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { Articles, HomePageMeta } from 'ion-article-cmp'
import Ad from '../../components/Ad'
import WingBanners from '../../components/WingBanners'
import { FORMATS, MAPPINGS } from '../../components/AdFormats'
import NoImage from '../../static/no-image.png'
import HomePageTemplate from '../../templates/Articles/HomePageHighlight'
import SectionHighlight from '../../templates/Articles/SectionHighlight'
import SiteSidebar from '../../components/Sidebar'
import logo from '../../static/general/isolezwe.svg'
import { MobileElse } from 'ion-media'
import { Link } from 'react-router-dom'
import { RightArrow } from 'ion-icon'

const LeaderboardTop = [
  [[1024, 0], [[728, 90], [970, 90], 'fluid']],
  [[640, 0], [[460, 60], 'fluid']],
  [[0, 0], [[320, 100], [320, 50], [300, 100], [300, 50], 'fluid']]
]

const Leaderboard = [
  [[1024, 0], [[728, 90], [970, 90], [970, 250], 'fluid']],
  [[640, 0], [[460, 60], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const Wing = [
  [[1024, 0], [[160, 600], [120, 600]]],
  [[640, 0], []],
  [[0, 0], []]
]

export class HomePage extends Component {
  constructor (props) {
    super(props)
    // Don't call this.setState() here!
    this.state = { isEmpty: true, is600: false }
  }

  render () {
    return (
      <>
        <WingBanners useSmall={this.props.useSmall} isConnected={this.props.isConnected} location={this.props.location} onSlotRenderEndedLeft={this.props.onSlotRenderEndedLeft} onSlotRenderEndedRight={this.props.onSlotRenderEndedRight} mappingLeft={Wing} mappingRight={Wing} />
        <Helmet title={process.env.RAZZLE_SITE_TITLE}>
          <meta property='og:url' content={process.env.RAZZLE_SITE_URL} />
          <meta name='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta itemProp='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta property='og:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta name='twitter:site' content='@dailyvoicesa' />
          <meta name='twitter:creator' content={process.env.RAZZLE_SITE_TWITTER_USER} />
          <meta name='twitter:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta name='twitter:title' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:image:src' content={logo} />
        </Helmet>
        <HomePageMeta {...this.props} />
        <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert-leaderboard' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
        <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert-leaderboard-top' mapping={LeaderboardTop} {...FORMATS.leaderboardtop} />
        <Articles {...this.props} component={HomePageTemplate} className='none' section='tvbox' count={7} pageSize={7} noImage={NoImage} />
        <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='leaderboard-top1' targeting={{ leaderboard: 'top1' }} collapseEmptyDiv className='advert-leaderboard' mapping={Leaderboard} {...FORMATS.leaderboard} />
        <div className='wrapper article-listing' ref={this.setStickyRef}>
          <div className='article-list'>
            {/* Izindaba Section */}
            <div className='section-head'>
              <h1><span>Izindaba</span></h1>
              <Link to='/izindaba'>More <RightArrow width='12' height='12' /></Link>
            </div>
            <Articles {...this.props} component={SectionHighlight} className='highlights' section='izindaba' count={5} pageSize={5} noImage={NoImage} />

            {/* Ezokungcebeleka Section */}
            <div className='section-head'>
              <h2><span>Ezokungcebeleka</span></h2>
              <Link to='/ezokungcebeleka'>More <RightArrow width='12' height='12' /></Link>
            </div>
            <Articles {...this.props} component={SectionHighlight} className='highlights' section='ezokungcebeleka' count={5} pageSize={5} noImage={NoImage} />

            {/* Ezemidlalo Section */}
            <div className='section-head'>
              <h2><span>Ezemidlalo</span></h2>
              <Link to='/ezemidlalo'>More <RightArrow width='12' height='12' /></Link>
            </div>
            <Articles {...this.props} component={SectionHighlight} className='highlights' section='ezemidlalo' sectionLabel='Ezemidlalo' count={5} pageSize={5} noImage={NoImage} />

            {/* Intandokazi Section */}
            <div className='section-head'>
              <h2><span>Intandokazi</span></h2>
              <Link to='/impilo-yabantu/intandokazi'>More <RightArrow width='12' height='12' /></Link>
            </div>
            <Articles {...this.props} component={SectionHighlight} className='highlights' section='impilo-yabantu/intandokazi' sectionLabel='Intandokazi' count={5} pageSize={5} noImage={NoImage} />

            {/* Impilo Yabantu Section */}
            <div className='section-head'>
              <h2><span>Impilo Yabantu</span></h2>
              <Link to='/impilo-yabantu'>More <RightArrow width='12' height='12' /></Link>
            </div>
            <Articles {...this.props} component={SectionHighlight} className='highlights' section='impilo-yabantu' sectionLabel='Impilo Yabantu' count={5} pageSize={5} noImage={NoImage} />

            {/* Imibono Section */}
            <div className='section-head'>
              <h2><span>Imibono</span></h2>
              <Link to='/imibono'>More <RightArrow width='12' height='12' /></Link>
            </div>
            <Articles {...this.props} component={SectionHighlight} className='highlights' section='imibono' sectionLabel='Imibono' count={5} pageSize={5} noImage={NoImage} />
          </div>
          <SiteSidebar {...this.props} section='homepage' sticky={this.state.sticky} />
        </div>
        <MobileElse>
          <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert-leaderboard' mapping={Leaderboard} {...FORMATS.leaderboard} />
        </MobileElse>
      </>
    )
  }
}

const mapStateToProps = (state) => ({ articles: state.articles })

export default connect(mapStateToProps)(HomePage)
