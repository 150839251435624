import Recaptcha from 'react-google-recaptcha'
import React, { Component, Fragment } from 'react'
import { Dimmer, Loader, Segment, Message } from 'semantic-ui-react/dist/commonjs'
import 'semantic-ui-css/components/dimmer.min.css'
import 'semantic-ui-css/components/loader.min.css'
import 'semantic-ui-css/components/segment.min.css'
import 'semantic-ui-css/components/message.min.css'
import Helmet from 'react-helmet'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { initialise, submitFeedback, updateForm } from '../../store/feedback'
import Ad from '../components/Ad'
import { FORMATS, MAPPINGS } from '../components/AdFormats'

const recaptchaRef = React.createRef()

export class Feedback extends Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
  }

  componentDidMount () {
    this.props.initialise()
  }

  handleChange (event) {
    const newState = {}
    newState[event.target.name] = event.target.value
    this.props.updateForm(newState)
  }

  validateEmail (email) {
    const re = /^[^.@][^@]+@{1}[^@]+\.[^@]+[^.@]$/i
    return email.match(re)
  }

  validateName (name) {
    const re = /^[`a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ' -]{2,45}$/i
    return name.match(re)
  }

  validatePhone (phone) {
    const re = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/
    return phone.match(re)
  }

  verifyCallback () {
    this.props.updateForm({
      captchaVerified: true,
      recaptchaValue: recaptchaRef.current.getValue()
    })
  }

  handleSubmit (event) {
    event.preventDefault()
    if (!this.validateEmail(this.props.email)) {
      this.props.updateForm({ output: 'Please enter a valid email address' })
      return false
    } else if (!this.validateName(this.props.fullName)) {
      this.props.updateForm({ output: 'Please enter a valid full name' })
      return false
    } else if (!this.validatePhone(this.props.phone)) {
      this.props.updateForm({ output: 'Please enter a valid cellphone number' })
      return false
    } else if (!this.props.captchaVerified) {
      this.props.updateForm({ output: 'Please enter a Captcha' })
      return false
    }
    this.props.submitFeedback(this.props)
    return true
  }

  render () {
    if (!this.props.isSubmitting && !this.props.hasSubmitted) {
      return (
        <>
          <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/feedback`} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert-leaderboard' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
          <div className='wrapper static-page'>
            <Helmet title='Ifomu lokuxhumana neSolezwe | Isolezwe'>
              <meta name='description' content='Sishayele noma sithumele i-email ngofisa ukukudlulisa kwithimba lezintatheli noma kuMhleli wePhephandaba.' />
            </Helmet>
            <h1>Ifomu lokuxhumana neSolezwe</h1>
            <form id='item-form' name='item-form' encType='text/plain' onSubmit={this.handleSubmit}>
              <p>Fill out the form below to send your comments, queries and feedback.</p>
              <label htmlFor='fullName'>Full Name:</label>
              <input id='fullName' placeholder='Type your full name' required='required' type='text' name='fullName' value={this.props.fullName} onChange={this.handleChange} />
              <label htmlFor='email'>Email:</label>
              <input id='email' placeholder='example@domain.com' required='required' type='email' name='email' value={this.props.email} onChange={this.handleChange} />
              <label htmlFor='phone'>Cellphone:</label>
              <input id='phone' placeholder='Type your cellphone number' required='required' type='text' name='phone' value={this.props.phone} onChange={this.handleChange} />
              <label htmlFor='enquiryType'>Enquiry:</label>
              <select id='enquiryType' name='enquiryType' onChange={this.handleChange}>
                <option value='general'>General Enquiry</option>
                <option value='technical'>Technical Issue</option>
                <option value='news'>News</option>
                <option value='business'>Business</option>
                <option value='sport'>Sport</option>
                <option value='motoring'>Motoring</option>
                <option value='lifestyle'>Lifestyle</option>
                <option value='travel'>Travel</option>
                <option value='entertainment'>Entertainment</option>
                <option value='newspapers'>Newspapers</option>
                <option value='press'>Press Release</option>
              </select>
              <label htmlFor='message'>Message:</label>
              <textarea name='message' id='message' onChange={this.handleChange} />
              <Recaptcha ref={recaptchaRef} sitekey={process.env.RAZZLE_GOOGLE_CAPTCHA_SITE_KEY} onChange={() => this.verifyCallback} />
              {(!(this.props.output === '') &&
                <Message
                  error
                  header='Errors'
                  content={this.props.output}
                />
              )}
              <input id='submitbutton' type='submit' value='Submit' className='green-btn' />
              <input type='hidden' name='form_hash' value='n1opdyilO0bEvwBq' id='form_hash' />
            </form>
          </div>
        </>
      )
    } else if (this.props.isSubmitting) {
      return (
        <>
          <Helmet title='Article' />
          <Segment>
            <Dimmer active>
              <Loader indeterminate size='large'>Submitting your feedback</Loader>
            </Dimmer>
          </Segment>
        </>
      )
    } else if (this.props.hasSubmitted) {
      return (
        <div className='content-wrapper'>
          <Helmet title='Thank you for your feedback' />
          <h1>Thank you for your feedback</h1>
          <p>Thank you for your feedback. We will get back to you shortly.</p>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => (state.feedback)
const mapDispatchToProps = (dispatch) => bindActionCreators({ initialise, submitFeedback, updateForm }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
