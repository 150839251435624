import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { PlayBtn } from 'ion-icon'
import { ImageOrNoImage } from 'ion-image'
class ArticleCard extends Component {
  render () {
    const article = this.props.article
    const showAbstract = this.props.showAbstract
    const showPlayBtn = this.props.showPlayBtn
    const noImage = this.props.noImage
    const width = this.props.width
    return (
      <article key={article.contentKey} aria-label='article'>
        <Link to={'/' + article.getCanonicalUri()}>
          {(showPlayBtn && <div className='play-btn'><PlayBtn width='48' height='48' /></div>)}
          <ImageOrNoImage image={article.image || (article.videos && { url: article.videos[0].thumbnailURL })} width={width || 371} alt={article.headline} shape='16x9' noImage={noImage} />
        </Link>
        <div className='content'>
          <small className='meta'>
            {article.formatAgo()} | <Link className='category' to={'/' + article.getSectionId()}>{article.sectionLabel}</Link>
          </small>
          <Link to={'/' + article.getCanonicalUri()}>
            <h3>{article.headline}</h3>
            {(showAbstract && <p>{article.getAbstract()}</p>)}
          </Link>
          {(article.sponsoredIndicator === 'SPONSORED' || article.sponsoredIndicator === 'SPONSORED_EXPIRED') &&
            <small className='sponsored'>Sponsored</small>}
          {(article.sponsoredIndicator === 'NATIVE' || article.sponsoredIndicator === 'NATIVE_EXPIRED') &&
            <small className='native'>Partnered</small>}
        </div>
      </article>
    )
  }
}

export default ArticleCard
