import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Dimmer, Loader, Segment } from 'semantic-ui-react/dist/commonjs'
import Helmet from 'react-helmet'
import 'semantic-ui-css/components/sticky.min.css'
import { Sticky } from 'semantic-ui-react'
import Ad from './Ad'
import FORMATS from './AdFormats'
import MostRead from '../components/MostReadArticles'
import EditorsChoice from '../components/EditorsChoice'
import { subscribeNewsletter, updateForm, reinitialize } from '../../store/subscribe'
import { fetchMagazineCover } from '../../store/magazineCover'
import { setMostReadTab } from '../../store/app'
import { Twitter, Facebook, Instagram, RSS as Rss } from 'ion-icon'

const mpuRHS = [
  [[1024, 0], [[300, 250], [3, 3], 'fluid']],
  [[640, 0], [[300, 250], [336, 280], [3, 3], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], [3, 3], 'fluid']]
]

export class Sidebar extends Component {
  constructor (props) {
    super(props)
    this.sticky = null
    this.setStickyRef = element => this.setState({ sticky: element })
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onTabMostReadSelected = this.onTabMostReadSelected.bind(this)
    this.onTabEditorsChoiceSelected = this.onTabEditorsChoiceSelected.bind(this)
    const lastFetch = new Date(this.props.lastFetch || 0)
    const now = new Date()
    if (!this.props.magazineCover || (now - lastFetch > 60000 * 60)) {
      this.props.fetchMagazineCover()
    }
  }

  handleChange (event) {
    const newState = {}
    newState[event.target.name] = event.target.value
    this.props.updateForm(newState)
  }

  validateEmail (email) {
    const re = /^[^.@][^@]+@{1}[^@]+\.[^@]+[^.@]$/i
    return email.match(re)
  }

  handleSubmit (event) {
    event.preventDefault()
    if (!this.validateEmail(this.props.email)) {
      this.props.updateForm({ error: 'Please enter a valid email address' })
      return false
    }
    this.props.subscribeNewsletter(this.props)
    return true
  }

  onTabMostReadSelected (e) {
    this.props.setMostReadTab(0)
  }

  onTabEditorsChoiceSelected (e) {
    this.props.setMostReadTab(1)
  }

  componentDidMount () {
    this.props.reinitialize()
    require('semantic-ui-css/components/sticky.min.js')
  }

  renderDate () {
    const monthNumbers = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December'
    }
    let today = new Date()
    let dd = today.getDate()
    let mm = today.getMonth() + 1
    const yyyy = today.getFullYear()
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }
    today = dd + ' ' + monthNumbers[mm] + ' ' + yyyy
    return today
  }

  render () {
    const props = this.props
    const showForm = !props.hasSubmited && !props.isSubmiting
    const submitting = props.isSubmiting
    const showThankYou = props.hasSubmited
    return (
      <aside className='sidebar' ref={this.setStickyRef}>
        <Sticky context={this.sticky}>
          <div className='read-choice'>
            <input id='most-read' type='radio' name='tabs' checked={this.props.mostReadTab === 0} onChange={() => this.onTabMostReadSelected} />
            <label htmlFor='most-read'>Eziphambili</label>
            <input id='editors-choice' type='radio' name='tabs' checked={this.props.mostReadTab === 1} onChange={() => this.onTabEditorsChoiceSelected} />
            <label htmlFor='editors-choice'>Umbono woMhleli</label>
            <div className='content' id='sidebar-articles'>
              <MostRead {...props} section='' title='Isolezwe' />
              <EditorsChoice {...props} section='' title='Isolezwe' />
            </div>
          </div>
          <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='mpu-topright1' targeting={{ mpu: 'topright1' }} collapseEmptyDiv className='advert-sidebar' mapping={mpuRHS} {...FORMATS.mpu} />
          <div className='follow-us'>
            <h3>
              <span>Follow us</span>
            </h3>
            <a href={process.env.RAZZLE_SITE_FACEBOOK} className='facebook' target='_blank' rel='noopener noreferrer'>
              <Facebook width='24' height='24' />
              <span>Like Isolezwe on Facebook</span>
            </a>
            <a href={process.env.RAZZLE_SITE_TWITTER} className='twitter' target='_blank' rel='noopener noreferrer'>
              <Twitter width='24' height='24' fill='#1D1D1D' />
              <span>Follow Isolezwe on Twitter</span>
            </a>
            <a href={process.env.RAZZLE_SITE_INSTAGRAM} className='instagram' target='_blank' rel='noopener noreferrer'>
              <Instagram width='24' height='24' />
              <span>Follow Isolezwe on Instagram</span>
            </a>
            <a href={process.env.RAZZLE_SITE_RSS} target='_blank' className='instagram' rel='noopener noreferrer'>
              <Rss width='24' height='24' />
              <span>Subscribe to Isolezwe RSS Feed</span>
            </a>
          </div>
          <div className='newsletter-subscribe'>
            <h3>
              <span>On Sale Now</span>
            </h3>
            {this.props.magazineCover &&
              <img src={this.props.magazineCover.image} alt='magazine cover' />}
          </div>
          {this.props.children}
          <div className='newsletter-subscribe'>
            <h3>
              <span>Subscribe</span>
            </h3>
            {showForm &&
              <>
                <form name='item-form' id='item-form' onSubmit={this.handleSubmit}>
                  <label htmlFor='email'>Sign up to receive breaking news in your inbox.</label>
                  {props.error !== '' &&
                    <small style={{ color: 'red' }}>{this.props.error ? this.props.error : 'An error occured, please try again'}</small>}
                  {props.email === '' &&
                    <input type='email' name='email' id='email' placeholder='Email Address' onChange={this.handleChange} required />}
                  {props.email !== '' &&
                    <input type='email' name='email' id='email' value={props.email} onChange={this.handleChange} autofocus='autofocus' onfocus='this.select()' />}
                  <input type='hidden' name='lists' value='[17381]' />
                  <button type='submit'>Subscribe</button>
                </form>
              </>}
            {submitting &&
              <>
                <Helmet title='Article' />
                <Segment>
                  <Dimmer active>
                    <Loader indeterminate size='large'>Submitting subscription.....</Loader>
                  </Dimmer>
                </Segment>
              </>}
            {showThankYou &&
              <>
                <Helmet title='Article' />
                <h3>Thank you for Subscribing!</h3>
                <p>We have sent a confirmation email to the email address that you provided. Click on the link in the email to confirm your subscription to our newsletter.</p>
              </>}
          </div>
          <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='mpu-bot' targeting={{ mpu: 'bot' }} collapseEmptyDiv className='advert-sidebar' {...FORMATS.mpu600} />
        </Sticky>
      </aside>
    )
  }
}

const mapStateToProps = (state) => (Object.assign({}, state.subscribe, state.app, state.magazineCover))

const mapDispatchToProps = (dispatch) => bindActionCreators({
  subscribeNewsletter,
  updateForm,
  reinitialize,
  setMostReadTab,
  fetchMagazineCover
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
