import React from 'react'
import ArticleCard from '../../components/ArticleCard'

const SectionHighlight = ({ articles, section, noImage, isConnected, sectionLabel, useH1 }) => (
  articles.map((article, index) => {
    if (index) {
      return <ArticleCard key={article.contentKey} article={article} noImage={noImage} width={100} />
    } else {
      return <ArticleCard key={article.contentKey} article={article} showAbstract noImage={noImage} width={315} />
    }
  })
)

export default SectionHighlight
